<template>
  <div class="disclosure">
    <h1>2025 BMW i4, i5, iX Charging</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color"><strong>Effective as of April 2024</strong></p>
    <p>
      Electrify America and BMW are pleased to provide this promotional charging program to drivers of new model year
      2025 BMW i4, i5, and iX vehicles purchased from an authorized BMW dealer. By clicking “Agree” below, you agree to
      participate in this promotion, subject to your compliance with these terms and conditions.
    </p>

    <p>
      Participation requires the My BMW app, enrollment in the 2025 BMW i4, i5, iX Charging plan, and acceptance of
      Electrify America’s Terms of Use and the end user license agreement or other terms of use that govern your use of
      the My BMW app. Your mobile carrier’s messaging, data, and other rates and fees will apply to your use of the My
      BMW app. You may also be required to use a credit or debit card for idle fees or other charging sessions not
      covered by your plan. See the Electrify America Terms of Use available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> for
      further details.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be enrolled in a program that provides
      1,000 kWh (or, for charging stations where pricing is calculated on a per-minute basis, the per-minute equivalent
      of 1,000 kWh), of complimentary DC fast (CCS) and Level 2 charging for your eligible BMW vehicle. You may redeem
      this credit for up to 24 months (starting from the date of vehicle purchase) on Electrify America’s network of
      electric vehicle charging stations including participating Commercial Fast Charging stations.
    </p>
    <p>
      After signing up, you can redeem your complimentary 1,000 kWh of charging during your initiation of a charging
      session at any Electrify America charging station and participating Commercial Fast Charging stations, by using
      the My BMW app “Swipe to start” feature with the appropriate plan selected. This complimentary charging may not be
      initiated using the interface screen or buttons on the charger. Plug&Charge functionality will also be available
      for use with this plan. Plug&Charge can help owners of capable BMW models have a better charging experience by
      creating a secure, convenient, and effortless way to pay for charging sessions on the Electrify America network.
    </p>
    <p>
      There is no additional fee, purchase, or subscription required for your access to this promotion. After the 1,000
      kWh credit is fully expended, you are responsible for all charging fees (including any applicable tax and idle
      fees).
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this complimentary credit for charging the BMW model whose VIN you submitted
      when signing up for this promotion.
      <strong>No other vehicle is eligible to receive this credit through your account,</strong> whether owned by you or
      others. This promotion is for first owners only and for your personal, private use only. You may not use this
      promotion benefit for commercial purposes, including but not limited to ride-hailing, rideshare, or fleet use.
    </p>
    <p>
      The complimentary 1,000 kWh charging <strong>does not include</strong> any idle fees and their associated
      applicable taxes assessed at the charging station. You will be responsible for paying such fees through your
      account if you incur them during a charging session. At the beginning of each charging session, Electrify America
      will pre-authorize your account for $20 dollars and in additional increments of $20 dollars to cover these
      potential charges in the event that they apply.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the BMW vehicle model when
      charging at an Electrify America charging station.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the BMW vehicle model at the
      charging station.
    </p>
    <p>
      Once the 1,000 kWh credit has been fully used or expired, you will not be able to reload, renew, or add more
      credit to this promotional plan. At that time, your account will automatically convert to a basic Electrify
      America “Pass” account, at no additional fee or charge to you (neither one-time nor recurring). Please refer to
      the information on the <strong>“Plan Details”</strong> section of the app to keep track of the amount of time and
      energy remaining in this promotion.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the app is of subject to the Terms of Use accessible by navigating to your Electrify America account,
      selecting <strong>“Legal”</strong> and then selecting <strong>“Terms of Use” & “Privacy Policy.”</strong> Please
      read these Terms of Use documents carefully for information about how you can pay through your account for idle
      fees and applicable taxes as well as for charging session time outside of the complimentary charging offered under
      this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, the My
      BMW end user license agreement or other terms of use, or any other contract between you and Electrify America or
      BMW; (b) have engaged in charging sessions that are excessive, fraudulent, or otherwise indicate an unauthorized
      or unintended use of this complimentary promotion; or (c) have engaged in any illegal, fraudulent, tortious,
      injurious, harmful, or abusive conduct in your use of the Electrify America services, the My BMW app, or an
      Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, provided that Electrify America will send an email to the
      address associated with your account in the event of any change to the scheduled end date of this promotion.
      Following the termination or expiration of this program for any reason, your access to the complimentary promotion
      will immediately cease, and you may not use or redeem any remaining portion of the complimentary charging time.
    </p>
  </div>
</template>

<script>
export default {
  name: '2025-bmw-i4i5iX-charging-disclosure',
  metaInfo: {
    title: '2025 BMW i4, i5,iX Charging Plan Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/2025-bmw-i4i5iX-charging-disclosure/' }],
  },
};
</script>
